import { doQuery, doAction } from "@/api/core"
import { mapGetters } from 'vuex'
import cmyUtils from "../../../utils/cmyUtils";
import redBag from  '@/components/red-bag/red-bag.vue'

export default {
    name: "examination",
    data() {
        return {
            /*输入参数,注意不是paper_id，任何考卷及考卷信息都从task_id和taskitem_id来查询到*/
            taskId: null,
            taskItemId: null,

            /*考试&考卷题目信息*/
            examination: null,
            answerSheet: null,

            answerAnalysis: null,

            //计时器
            timeCount: null,
            timeCountBoard: null,
            timeHandler:  null,

            addWrongQuestionModal: false,

            wrongTags: [],
            wrongQuestionComments: '',
            wrongQuestionTag:'',

            questionIdSelected: null,


        }
    },

    components: {
        redBag
    },

    computed: {
        ...mapGetters([
            'staffId'
        ]),

        //答题进度
        answerSheetLen() {
            const that = this
            var cnt = 0;
            if (this.answerSheet) {
                this.answerSheet.forEach(function(item, index) {
                    if(item.type == that.$consts.biz.QUES_TYPES.SINGLE || item.type == that.$consts.biz.QUES_TYPES.MULTI || item.type == that.$consts.biz.QUES_TYPES.JUDGE) {
                        if(item.answer != null && item.answer != '') {
                            ++cnt
                        }
                    }else if(item.type == that.$consts.biz.QUES_TYPES.BLANK) {
                        if(item.answer instanceof Array && item.answer.join('') != '') {
                            ++cnt
                        }
                    }else if(item.type == that.$consts.biz.QUES_TYPES.TEXT) {
                        if(item.answer != null && item.answer != '') {
                            ++cnt
                        }
                    }

                })
            }
            return cnt;
        },

        //是否全部答题
        answerFinished() {
            return (this.examination && this.examination.questions && this.answerSheetLen >= this.examination.questions.length)
        }
    },
    mounted() {
        var that = this;

        if(this.$route.params) {
            if(this.$route.query.taskId) {
                this.taskId = this.$route.query.taskId
            }
            if(this.$route.query.taskItemId) {
                this.taskItemId = this.$route.query.taskItemId
            }
            this.refreshExam(() => {

                this.startTimeCount()

                if(this.examination.is_passed == '1') {
                    this.refreshExamQuestions2(this.examination.paper_id, this.examination.exam_id)
                }
            })
            this.queryWrongTags()
        }

    },

    beforeDestroy() {

        //清除定时器
        const that = this
        if (that.timeHandler) {

            //清除定时器
            clearInterval(that.timeHandler)
            that.timeHandler = null
        }

    },

    methods: {

        /**启动计数器*/
        startTimeCount(){

            const that = this;

            //已经通过，无需计时
            if (that.examination.is_passed == '1' ) {
                return
            }

            //不限时，无需计时
            if (that.examination.time_pass <= 0) {
                return
            }

            //没有考试次数，无需计时
            if (that.examination.leave_examtimes <= 0) return;

            that.timeHandler = setInterval(() => {
                if(that.timeCount < 0) {
                    that.$message.error('考试时间已到')

                    //清除定时器
                    clearInterval(that.timeHandler)
                    that.timeHandler = null

                    /*自动提交试卷 add by ycp*/
                    this.$confirm({
                        title: '温馨提示',
                        content: '考试时间已到，本次考试即将结束！确定后将自动提交答题卡',
                        onOk() {
                            that.submitExaminationResult();
                        },
                        onCancel() {
                        },
                    });

                }else {
                    that.timeCount = that.timeCount - 1
                    that.timeCountBoard = Math.floor(that.timeCount/60) + '  分  ' + (that.timeCount%60) + '  秒'
                }
            },1000)

        },


        refreshExam(func) {
            const that = this
            doQuery({
                query_code: 'LNE00',
                param_str2: (that.taskId ? that.taskId : ''),
                param_str3: (that.taskItemId ? that.taskItemId : ''),
                page: 1,
                limit: 10
            }).then(res => {
                if(res.errcode >= that.$consts.biz.OK && res.data && res.data.length > 0) {
                    //考试基本信息
                    that.examination = res.data[0]

                    //计数器
                    if(that.timeCount == null) {

                        that.timeCount = that.examination.time_pass * 60
                        that.timeCountBoard = Math.floor(that.timeCount/60) + '  分  ' + (that.timeCount%60) + '  秒'

                    }

                    //如果之前没有taskitem_id，此时设置上
                    that.taskItemId = that.examination.taskitem_id

                    //刷新考试题目信息
                    that.refreshExamQuestions(that.examination.paper_id, that.examination.exam_id)
                    if(func) {
                        func()
                    }
                }
            })
        },

        refreshExamQuestions(paperId, examId) {
            const that = this
            doQuery({
                query_code: 'LNE10',
                param_str2: (paperId ? paperId : ''),
                param_str3: (examId ? examId : ''),
                page: 1,
                limit: 1
            }).then(res => {
                if(res.errcode >= that.$consts.biz.OK && res.data && res.data.length > 0) {
                    //考试题目信息
                    var questions = []
                    that.answerSheet = []
                    res.data.forEach(function(item, index) {

                        let question_item = {
                            question_id: item.question_id,
                            question: item.question,
                            score: item.score,
                            type: item.type,
                            options: [
                                {
                                    number: 'A',
                                    text: item.answer1
                                },
                                {
                                    number: 'B',
                                    text: item.answer2
                                },
                                {
                                    number: 'C',
                                    text: item.answer3
                                },
                                {
                                    number: 'D',
                                    text: item.answer4
                                },
                                {
                                    number: 'E',
                                    text: item.answer5
                                },
                                {
                                    number: 'F',
                                    text: item.answer6
                                },
                            ],
                            blank: item.type == that.$consts.biz.QUES_TYPES.BLANK ? (cmyUtils.getStrCount(item.question,'()') + cmyUtils.getStrCount(item.question,'（）')) : 0
                        }

                        questions.push(question_item)

                        var answer = null
                        if(item.type == that.$consts.biz.QUES_TYPES.SINGLE || item.type == that.$consts.biz.QUES_TYPES.MULTI || item.type == that.$consts.biz.QUES_TYPES.JUDGE) {
                            answer = item.answer ? (item.answer.length == 1 ? item.answer : item.answer.split('|')):item.answer
                        }else if(item.type == that.$consts.biz.QUES_TYPES.BLANK) {
                            // 给个默认值 在页面上好v-model
                            answer = []
                            for(var i = 0; i < question_item.blank; i++) {
                                answer.push('')
                            }
                            if(item.answer) {
                                answer = item.answer.split('|')
                            }
                        }else if(item.type == that.$consts.biz.QUES_TYPES.TEXT) {
                            // 给个默认值 在页面上好v-model
                            answer = null
                            if(item.answer) {
                                answer = item.answer
                            }
                        }
                        that.answerSheet.push({
                            question_id: item.question_id,
                            answer: answer,
                            type: item.type
                        });
                    })
                    that.$set(that.examination, 'questions', questions)
                }
            })
        },

        submitExaminationResultPre() {
            const that = this
            if (that.examination.is_passed == '1') {
                that.$message.error('你已经通过本次考试，无法重复交卷')
                return
            }
            if (that.answerSheetLen == 0) {
                that.$message.error('你还没有开始答题哦')
                return
            }
            if (that.answerSheetLen > 0 && that.answerSheetLen < that.answerSheet.length && that.examination.time_pass > 0) {
                that.$message.error('限时考试必须全部答完才能交卷哦')
                return
            }
            if (that.answerSheetLen > 0 && that.answerSheetLen < that.answerSheet.length && that.examination.time_pass == 0) {
                that.$confirm({
                    centered: true,
                    title: '你还没有全部答完，是否暂存本次答题信息？',
                    onOk() {
                        that.submitExaminationResult()
                    }
                })
            }
            if (that.answerSheetLen > 0 && that.answerSheetLen >= that.answerSheet.length && that.examination.time_pass > 0 && that.timeCount < 0){
                that.$message.error('本次考试已经超时, 请努力后再次尝试吧')
            }else {
                that.submitExaminationResult()
            }
        },

        submitExaminationResult() {
            const that = this

            var answersheet = JSON.parse(JSON.stringify(that.answerSheet))
            console.log(answersheet)
            answersheet.forEach(item => {
                if(item.answer &&
                    (item.type == that.$consts.biz.QUES_TYPES.MULTI || item.type == that.$consts.biz.QUES_TYPES.BLANK) &&
                  item.answer instanceof Array) {
                    item.answer = item.answer.join('|')
                }
            })

            doAction({
                action_code: 'LNE0',
                param_str5: JSON.stringify({
                    staff_id: that.staffId,
                    task_id: that.taskId ? that.taskId : '',
                    taskitem_id: that.taskItemId ? that.taskItemId : '',
                    exam_id: that.examination.exam_id ? that.examination.exam_id : '',
                    exam_begintime: that.examination.rightnow,
                    answersheet: answersheet
                })
            }).then(res => {
                if(res.errcode >= that.$consts.biz.OK ) {
                    if(res.data.errcode >= that.$consts.biz.OK) {
                        that.$message.success('提交考卷成功')
                        that.refreshExam(() => {
                             that.refreshExamQuestions2(that.examination.paper_id, that.examination.exam_id)
                        })
                    }else {
                        that.$message.error(that.$consts.biz.FAIL_MSG)
                    }
                }else {
                    that.$message.error(that.$consts.biz.FAIL_MSG)
                }
            })
        },

        refreshExamQuestions2(paperId, examId) {
            const that = this
            if(that.examination.is_passed == '1') {

                //打开红包
                that.$nextTick(()=>{

                    setTimeout(function(){

                        that.$refs.redbag.openBonus()

                    },1000)

                })

                //查询考试结果
                doQuery({
                    query_code: 'LNE1X',
                    param_str2: (paperId ? paperId : ''),
                    param_str3: (examId ? examId : ''),
                    page: 1,
                    limit: 1
                }).then(res => {
                    if(res.errcode >= that.$consts.biz.OK && res.data && res.data.length > 0) {
                        that.answerAnalysis = []
                        res.data.forEach((item, index) => {
                            var answerok = ''
                            if(item.type == that.$consts.biz.QUES_TYPES.MULTI || item.type == that.$consts.biz.QUES_TYPES.SINGLE || item.type == that.$consts.biz.QUES_TYPES.JUDGE) {
                                if(item.answerok) {
                                    if(item.answerok.length == 1) {
                                        answerok = that.$consts.biz.ANSWER_DICTIONARY[item.answerok]
                                    }else if(item.answerok.length > 1) {
                                        var arr = item.answerok.split('|')
                                        arr.forEach((a, aIndex) => {
                                            answerok += that.$consts.biz.ANSWER_DICTIONARY[a]
                                            if(aIndex < arr.length - 1) {
                                                answerok = answerok.concat('，')
                                            }
                                        })
                                    }

                                    if (!answerok || answerok=='' || answerok=='undefined')
                                        answerok = '【***】'
                                }
                            }else if(item.type == that.$consts.biz.QUES_TYPES.BLANK) {
                                if(item.answerok) {
                                    answerok = item.answerok.replaceAll('|','、')
                                }

                            }else if(item.type == that.$consts.biz.QUES_TYPES.TEXT) {
                                if(item.answerok) {
                                    answerok = item.answerok
                                }
                            }


                            that.answerAnalysis.push({
                                question_id: item.question_id,
                                answerok: answerok,
                                answerok_: item.answerok,
                                answer_: item.answer,
                                correct: item.score == item.score_get,
                                comments: item.answer_comments || '无',
                                can_check: item.can_check
                            })
                        })
                    }
                })
            }else {
                doQuery({
                    query_code: 'LNE20',
                    param_str2: (examId ? examId : ''),
                    param_str3: (paperId ? paperId : ''),
                    page: 1,
                    limit: 1
                }).then(res => {
                    if(res.errcode >= that.$consts.biz.OK && res.data && res.data.length > 0) {
                        that.answerAnalysis = []
                        res.data.forEach((item, index) => {
                            that.answerAnalysis.push({
                                question_id: item.question_id,
                                correct: item.is_correct == '1',
                            })
                        })
                    }
                })
            }
        },
        queryWrongTags() {
            const that = this
            doQuery({
                query_code: 'LN010',
                page: 1,
                limit: 10
            }).then(res => {
                if(res.errcode >= that.$consts.biz.OK && res.data) {
                    that.wrongTags = res.data
                }
            })
        },
        showAddWrongQuestionModal(questionId) {
            const that = this
            that.wrongQuestionComments = ''
            that.wrongQuestionTag = ''
            that.questionIdSelected = questionId
            that.addWrongQuestionModal = true
        },
        doAddWrongQuestion() {
            const that = this
            if(!that.wrongQuestionTag) {
                that.$message.error('请至少选择一个标签')
                return
            }
            doAction({
                action_code: 'LNW0',
                param_str2: that.questionIdSelected,
                param_str3: this.examination.exam_id,
                param_str4: that.wrongQuestionTag,
                param_str5: (that.wrongQuestionComments?that.wrongQuestionComments:'')
            }).then(res => {
                if(res.errcode >= that.$consts.biz.OK ) {
                    if(res.data.errcode >= that.$consts.biz.OK) {
                        that.$message.success('成功加入错题集')
                        that.addWrongQuestionModal = false
                    }else {
                        that.$message.error(res.data.errmsg)
                    }
                }else {
                    that.$message.error(that.$consts.biz.FAIL_MSG)
                }
            })
        }
    }
}
