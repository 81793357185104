<template>
    <div>
        <!-- 左侧红包 -->
        <div class="r_bag" v-if="leftBtnShow">
            <img class="bag1"   src="http://public.wencaishui.com/images/learning/pc/icon/bag1.png" mode="" @click="openrbagbtn()">
            </img>
        </div>

        <!-- 红包封面 -->
        <div class="rbag_model" v-if="rbagmodelshow" @touchmove.prevent.stop>
            <div class="rbag_con hidden">
                <div class="rbag_top">
                    <div class="rbag_top_info">
                        <img class="rbag_logo" src="http://public.wencaishui.com/images/learning/pc/icon/logo.jpg" mode=""></img>
                        <div class="app_name">小小奖励，不成敬意</div>
                        <div class="rbag_tips">恭喜完成任务</div>
                    </div>
                </div>
                <div class="open_rbag_btn" @click="openbtn()" style="cursor:pointer;">開</div>
            </div>
            <div class="rbag_con">
                <div class="hide_btn" @click.stop="hidebtn">
                    <a-icon type="close-circle"  style="color:#fbd977;font-size:32px;"/>
                </div>
            </div>
        </div>

        <!-- 打开红包 -->
        <div class="open_rbag_model" v-if="openrbagmodelshow" @touchmove.prevent.stop>
            <img class="rbag_conbg" src="http://public.wencaishui.com/images/learning/pc/icon/openrbag.png"></img>
            <div class="rbag_conbg open_rbag_con">
                <div class="open_title">— 恭喜您获得 —</div>
                <div class="rbag_detail">
                    <div class="open_money">
                        <countup :rollNumber="num"></countup>
                        <div v-if="num_type == '1' || num_type == '2'" class="danwei">元</div>
                        <div v-if="num_type == '3' || num_type == '4'" class="danwei">学豆</div>
                    </div>
                    <div v-if="num_type == '1' || num_type == '2'" class="open_tips">收下后五分钟内发放到微信零钱</div>
                    <div v-if="num_type == '3' || num_type == '4'" class="open_tips">收下后立刻发放到您的学豆账户</div>
                </div>
                <div class="lookbag_box">
                    <div class="lookbag_btn">
                        <div class="text" @click.stop="lookbagbrn()" style="cursor:pointer;">立刻收下</div>
                    </div>
                </div>
                <div class="hide_btn" @click.stop="hideopenbtn()">
                    <a-icon type="close-circle"  style="color:#fbd977;font-size:32px;"/>
                </div>
            </div>
        </div>


    </div>
</template>

<script>
    import countup from '@/components/countUp/countUp.vue';
    import { doQuery,doAction } from "@/api/core"

    export default {
        name: "red-bag",

        //领取红包的任务id
        props: {
            task_id: {
                type: String,
                default: null
            }
        },

        data() {
            return {
                num: 0,
                num_type: '0',
                leftBtnShow: false,
                rbagmodelshow: false,
                openrbagmodelshow: false,
            };
        },

        mounted() {
            var that = this;
        },

        methods: {

            //获取红包信息
            openBonus: function(func) {

                var that = this;

                doQuery({
                    query_code: 'LNBO10',
                    param_str2: that.task_id,
                    page: 1,
                    limit: 1
                }).then(res => {
                    if(res.errcode === that.$consts.biz.OK && res.data && res.data.length >0 && res.data[0].bonus >0) {

                        that.num_type = res.data[0].bonus_type

                        if (that.num_type == '1' || that.num_type == '2')
                            that.num = (res.data[0].bonus / 100).toFixed(2)
                        if (that.num_type == '3' || that.num_type == '4')
                            that.num = res.data[0].bonus

                        //展示领取红包按钮
                        if (!that.leftBtnShow)
                            that.leftBtnShow = true;

                        //展示打开按钮
                        that.rbagmodelshow = true;

                        //回调
                        if (func) func();
                    } else {

                        //如果有左边按钮展示，则提示否则不提示
                        if (that.leftBtnShow)
                            uni.showToast({
                                icon: 'none',
                                title: res.data[0].comments
                            })

                        that.rbagmodelshow = false;
                    }
                })

            },

            // 侧边红包 => 点击
            openrbagbtn: function() {
                this.openBonus();
            },

            // 红包封面 => 关闭按钮
            hidebtn: function() {
                this.rbagmodelshow = false;
            },

            // 红包封面 => 開红包按钮
            openbtn: function() {
                var that = this;

                setTimeout(function() {
                    that.rbagmodelshow = false;
                    that.openrbagmodelshow = true;
                }, 100);
            },

            // 打开红包  => 关闭按钮
            hideopenbtn: function() {
                this.openrbagmodelshow = false;
            },

            // 打开红包  => 查看钱包
            lookbagbrn: function() {

                var that = this;

                doAction({
                    action_code: 'LNBO20',
                    param_str2: that.task_id
                }).then(res => {

                        if (res.errcode == that.$consts.biz.OK) {

                            that.rbagmodelshow = false;
                            that.openrbagmodelshow = false;

                            that.$message.success('红包领取成功')

                        } else {

                            that.$message.success('红包领取失败')

                        }

                    })

            },
        },

        components: {
            countup
        },
    }
</script>

<style lang="scss">
    @import '@/style/red_bag.scss'
</style>
